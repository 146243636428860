import ComingSoon from "react-coming-soon";

function App() {
  return (
    <ComingSoon
      title="Rarebill"
        subtitle="NFT Market Place"
        bgColor="#360068"
        textColor="#fafafa"
        date="Fri Apr 29 2022 24:00:00 GMT+0530 (Indian Standard Time)"
        illustration="development"
      // image={"https://react-coming-soon.maksv.me/default-image.jpeg"}
    />
  );
}

export default App;
